//
// _footer.scss
//

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: $footer-color;
  height: $footer-height;
  background-color: $footer-bg;

  @media (max-width: 768px) {
    position: sticky;
  }
}

@media (max-width: 992px) {
  .footer {
    left: 0;
  }
}

// Enlarge menu
.vertical-collpsed {
  .footer {
    left: $sidebar-collapsed-width;
  }
}

body[data-layout='horizontal'] {
  .footer {
    left: 0 !important;
  }
}
